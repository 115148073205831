<template>
  <div ref="dom" class="container" :class="{ 'page-ready': isLoaded }">
    <div ref="domTitle" class="title">
      <img ref="domPict" class="pict" />
    </div>
    <div>
      <div
        class="list flex flex-wrap justify-content-center align-content-start card-container blue-container"
      >
        <div
          v-for="id in ids"
          :key="id"
          class="item flex align-items-center justify-content-center m-1"
        >
          <a
            href=""
            v-if="byPlant[id].data"
            style="
              width: 100%;
              height: 100%;
              position: relative;
              display: block;
              color: black;
            "
          >
            <div
              class="item-preview"
              :style="{
                'background-image': `url('${genUrl(byPlant[id].data.Preview, 80)}`,
              }"
            ></div>
            <div class="item-info">
              <span class="font-bold"
                >{{ byPlant[id].quantity }} x {{ byPlant[id].data.LatinName }}</span
              >
              <br />
              <span class="text-sm text-600">{{ byPlant[id].data.Name }}</span>
              <br />
              <span v-if="showMissingPrice && !byPlant[id].data.Price" style="color: red"
                >Missing Price</span
              >
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="footer">
      <div v-if="showPrice" class="mb-2">
        Estimation des végétaux* : {{ price }} €
        <div>
          *Le prix peut varier en fonction du fournisseur et de la taille des plantes
        </div>
      </div>
      <div class="flex justify-content-center">
        <div ref="domLogo" class="bg logo mx-1" />
        <div class="flex justify-content-center">
          <div
            v-if="imgPartnerUrl && partnerLabel && partnerLabel !== 'HortusBox'"
            class="flex uppercase align-items-center justify-content-center mr-1"
          >
            partenaire de
          </div>
          <div ref="domPartner" class="bg logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { usePressService } from "../../compositions/services/usePressService";

export default {
  setup() {
    const domTitle = ref(null);
    const domLogo = ref(null);
    const domPict = ref(null);
    const domPartner = ref(null);
    const dom = ref(null);
    const route = useRoute();
    const { genUrl, loadImage, getPlantsList } = usePressService();

    const isTitleLoaded = ref(false);
    const isLogoLoaded = ref(false);
    const isPictLoaded = ref(false);
    const isPartnerLoaded = ref(false);
    const isBgLoaded = ref(false);
    const timeout = ref(false)

    const imgTitleUrl = genUrl(route.query.tt);
    const imgLogoUrl = genUrl(route.query.lg);
    const imgPictUrl = genUrl(route.query.pc);
    const imgPartnerUrl = genUrl(route.query.ptl);
    const imgBgUrl = genUrl(route.query.bg);
    const partnerLabel = route.query.ptt;
    const estimatedPrice = route.query.price;
    const currentPage = route.query.page;
    const totalPages = route.query.total;

    let list = [];
    if (typeof route.query.ls === "string") {
      list.push(route.query.ls);
    } else {
      list = route.query.ls;
    }

    const price = ref(0);
    const showMissingPrice = ref(false);
    const showPrice = ref(false);

    if (estimatedPrice) {
      price.value = estimatedPrice;
      showMissingPrice.value = false;
    }

    if (estimatedPrice !== "hide" && currentPage === totalPages) {
      showPrice.value = true;
    }

    const byPlant = reactive({});
    const ids = [];
    list.forEach((one) => {
      const fields = one.split(":");
      if (fields.length != 2) {
        return;
      }
      const plantId = fields[0];
      const quantity = parseInt(fields[1]) || 0;
      if (!quantity) {
        return;
      }
      ids.push(plantId);

      byPlant[plantId] = {
        data: null,
        quantity: quantity,
      };
    });

    setTimeout(() => {
      timeout.value = true
    }, 5000)

    const isLoaded = computed(() => {
      return (
        isTitleLoaded.value &&
        isLogoLoaded.value &&
        isBgLoaded.value &&
        isPictLoaded.value &&
        isPartnerLoaded.value &&
        timeout.value
      );
    });

    getPlantsList(ids)
      .then(({ data }) => {
        data.forEach((one) => {
          byPlant[one.id].data = one;
        });

        if (!price.value) {
          showMissingPrice.value = true;
          ids.forEach((id) => {
            price.value += byPlant[id].quantity * byPlant[id].data.Price;
          });

          price.value += 0.2 * price.value;
          price.value = Math.round(price.value / 10);
          price.value *= 10;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    onMounted(() => {
      // ////
      loadImage(imgBgUrl, dom, true, () => {
        isBgLoaded.value = true;
      });
      // ////
      loadImage(imgLogoUrl, domLogo, true, () => {
        isLogoLoaded.value = true;
      });
      // ////
      loadImage(imgTitleUrl, domTitle, true, () => {
        isTitleLoaded.value = true;
      });
      // ////
      loadImage(imgPictUrl, domPict, false, () => {
        isPictLoaded.value = true;
      });
      // ////
      loadImage(imgPartnerUrl, domPartner, true, () => {
        isPartnerLoaded.value = true;
      });
    });

    return {
      route,
      isLoaded,
      partnerLabel,
      list,
      ids,
      byPlant,
      price,
      showMissingPrice,
      showPrice,
      dom,
      domTitle,
      domLogo,
      domPict,
      domPartner,

      imgPartnerUrl,

      genUrl,
    };
  },
};
</script>

<style scoped>
.container {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
}
.list {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 88vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.item {
  width: 320px;
  height: 80px;
}
.item-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.item-info {
  position: absolute;
  top: 15px;
  left: 90px;
}
.title {
  width: 100%;
  position: fixed;
  top: 1vh;
  height: 10vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.logo {
  /* background-color: red; */
  height: 80px;
  width: 180px;
}
.pict {
  position: fixed;
  top: 1vh;
  left: 1vh;
  height: 10vh;
}
.footer {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 6vh;
  left: 0;
  height: 10vh;
}
.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
