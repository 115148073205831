<template>
  <div ref="dom" class="container" :class="{'page-ready': isLoaded}">
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { usePressService } from '../../compositions/services/usePressService'

export default {
  setup() {
    const dom = ref(null)
    const isLoaded = ref(false)
    const route = useRoute()
    const { token } = usePressService()

    const loadImage = (url, cb) => {
      if (!url) {
        return cb('EmptyUrl')
      }
      const img = new Image()
      img.onerror = (err) => {
        cb(err)
      }
      img.onload = () => {
        cb(null)
      }
      img.src = url
    }

    onMounted(() => {
      const url = route.params.mediaId ? `/core/data/assets/${route.params.mediaId}?hortus-token=${token}` : ''
      loadImage(url, err => {
        if (err) {
          console.log(err)
        } else {
          dom.value.style.backgroundImage = `url('${url}')`
        }
        isLoaded.value = true
      })
    })

    return {
      route,
      isLoaded,
      dom,
    }
  },
}
</script>

<style scoped>
.container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
}
</style>