<template>
  <div ref="dom" class="container" :class="{'page-ready': isLoaded}">
    <div ref="domTitle" class="title">
      <img v-if="imgLogoUrl" ref="domLogo" class="logo">
    </div>
    <div ref="domPlan" class="plan"></div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePressService } from '../../compositions/services/usePressService'

export default {
  setup() {
    const domTitle = ref(null)
    const domLogo = ref(null)
    const domPlan = ref(null)
    const route = useRoute()
    const { genUrl, loadImage } = usePressService()

    const isTitleLoaded = ref(false)
    const isLogoLoaded = ref(false)
    const isPlanLoaded = ref(false)

    const imgTitleUrl = genUrl(route.query.titleId)
    const imgLogoUrl = genUrl(route.query.logoId)
    const imgPlanUrl = genUrl(route.params.mediaId, 1280)

    const isLoaded = computed(() => {
      return isTitleLoaded.value && isLogoLoaded.value && isPlanLoaded.value
    })

    onMounted(() => {
      // ////
      loadImage(imgPlanUrl, domPlan, true, () => {
        isPlanLoaded.value = true
      })
      // ////
      loadImage(imgLogoUrl, domLogo, false, () => {
        isLogoLoaded.value = true
      })
      // ////
      loadImage(imgTitleUrl, domTitle, true, () => {
        isTitleLoaded.value = true
      })
    })

    return {
      route,
      isLoaded,
      
      domTitle,
      domLogo,
      domPlan,

      imgTitleUrl,
      imgLogoUrl,
      imgPlanUrl,
    }
  },
}
</script>

<style scoped>
.container {
  height: 100vh;
}
.plan {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 88vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.title {
  width: 100%;
  position: fixed;
  top: 1vh;
  height: 10vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.logo {
  position: fixed;
  top: 1vh;
  left: 1vh;
  height: 10vh;
}
</style>