import http from '../../services/http'

// const version = 1

const token = 'e44fb5d4-a433-483e-838e-8f563a286433'

const logoHbId = 'c88e9486-33c3-47fe-8848-a0598daac985'

const getById = async function (id) {
  const { data } = await http.core.get(`/data/items/Hortus_Projects/${id}?fields=HortusClient.*,Documents.*,Partner.*,Pictures.Picture.*,Pictures.*,HortusBooks.*,HortusBooks.PlantsShoppingList.*,HortusBooks.PlantsShoppingList.Plant.*,HortusBooks.Plans.*,HortusBooks.BeforeAfter.*,Translations.*,*&hortus-token=${token}`)
  return data
}

const getPlantsList = async function (ids) {
  const { data } = await http.core.get(`/data/items/Inventaire_Plants?filter[id][_in]=${ids.join(',')}&hortus-token=${token}`)
  return data
}

const getMaterialsList = async function (ids) {
  const { data } = await http.core.get(`/data/items/Inventaire_Materials?filter[id][_in]=${ids.join(',')}&hortus-token=${token}`)
  return data
}

const genUrl = (mediaId, height) => {
  const qHeight = height ? `&height=${height}` : ''
  return mediaId ? `/core/data/assets/${mediaId}?hortus-token=${token}${qHeight}` : ''
}


const loadImage = (url, dom, isBg, cb) => {
  if (!url) {
    dom.value.style.display = 'none'
    return cb('EmptyUrl')
  }
  const img = new Image()
  img.onerror = (err) => {
    dom.value.style.display = 'none'
    cb(err)
  }
  img.onload = () => {
    if (isBg) {
      dom.value.style.backgroundImage = `url('${url}')`
    } else {
      dom.value.src = url
    }
    cb(null)
  }
  img.src = url
}

export function usePressService() {
  return {
    token,
    logoHbId,
    loadImage,
    genUrl,
    getById,
    getPlantsList,
    getMaterialsList
  }
}
