<template>
  <div ref="domAfter" class="after" :class="{'page-ready': isLoaded}">
    <img ref="domBefore" class="before" />
    <img ref="domLogo" class="logo" />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePressService } from '../../compositions/services/usePressService'

export default {
  setup() {
    const route = useRoute()
    const { genUrl, loadImage } = usePressService()

    const domAfter = ref(null)
    const domBefore = ref(null)
    const domLogo = ref(null)

    const isAfterLoaded = ref(false)
    const isBeforeLoaded = ref(false)
    const isLogoLoaded = ref(false)

    const imgAfterUrl = genUrl(route.params.mediaId, 1280)
    const imgBeforeUrl = genUrl(route.query.beforeId, 1280)
    const imgLogoUrl = genUrl(route.query.lg)

    const isLoaded = computed(() => {
      return isAfterLoaded.value && isBeforeLoaded.value && isLogoLoaded.value
    })

    onMounted(() => {
      loadImage(imgAfterUrl, domAfter, true, () => {
        isAfterLoaded.value = true
      })
      loadImage(imgBeforeUrl, domBefore, false, () => {
        isBeforeLoaded.value = true
      })
      loadImage(imgLogoUrl, domLogo, false, () => {
        isLogoLoaded.value = true
      })
    })

    return {
      route,
      isLoaded,
      domAfter,
      domBefore,
      domLogo
    }
  },
}
</script>

<style scoped>
.after {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 90vh;
}

.before {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 20vh;
  background-color: white;
  padding: 8px;
  margin: 12px;
  border-radius: 2px;
}

.logo {
  position: fixed;
  bottom: 2px;
  left: 5px;
  height: 10vh;
  padding: 8px;
}

</style>